<template>
    <div class="home center">
        <div class="col-1">
            <NewsItem/>
            <section class="shadow">
                <ForumItem/>
                <ForumTwoItem/>
                <Forum3Item/>
            </section>
            <div class="server">
                <img src="https://www.game-state.com/191.96.224.79:25283/560x95_FFFFFF_990000_000000_000000.png" alt="www.Game-State.com" style="border-style: none;" />
            </div>
        </div>
        <aside>
            <EventsItem/>
            <BadgesFree/>
        </aside>
    </div>
</template>

<script>
import NewsItem from '@/components/home/news'
import EventsItem from '@/components/home/events'
import ForumItem from '@/components/home/forum.vue'
import ForumTwoItem from '@/components/home/forum-2.vue'
import Forum3Item from '@/components/home/forum-3.vue'
import BadgesFree from '@/components/home/badges-free.vue'

export default {
    name: 'HomePage',
    components: {
        NewsItem,
        EventsItem,
        ForumItem,
        BadgesFree,
        ForumTwoItem,
        Forum3Item
    },
}
</script>

<style scoped>

.home {
    position: relative;
    margin-top: 40px;
    z-index: 0;
}

.col-1 {width: 65%;}

aside {
    position: relative;
    right: 0;
    top: 0;
    width: 33%;
    z-index: 1;
}

.shadow {
    height: auto;
    padding: 20px 0;
    margin-top: 30px;
    background-color: var(--color2);
    box-shadow: 0 0 0 100vmax var(--color2);
    clip-path: inset(0 -100vmax);
}

.home .server {
    float: left;
    display: inline;
    width: 100%;
    height: 100px;
    display: inline;
    margin-left: 50%;
    /* transform: translateX(-50%); */
    margin: 30px 0 0 0;
}

.home .server img {border-radius: 10px;}

@media (max-width: 500px) {
    .col-1 {width: 100%;}

    aside {width: 100%;margin: 0;}

    .home {flex-wrap: wrap; padding: 0 10px;}

    .home .server img {width: 100%;}
}
</style>