<template>
	<div class="forum item">
		<div class="top">
			<div class="title">
				<div class="icon"><i class="fa-solid fa-gavel"></i></div>
				<span>Três Poderes</span>
			</div>
			<div class="actions">
				<div class="input">
					<i class="fa-solid fa-magnifying-glass"></i>
					<input type="text" placeholder="Pesquise" v-model="search" @keypress.enter="getTopics()">
				</div>
				<button :disabled="prev <= 1"><i class="fa-solid fa-arrow-left"></i></button>
				<button :disabled="next > total"><i class="fa-solid fa-arrow-right"></i></button>
			</div>
		</div>
		<div class="content">
			<router-link :to="{ name: 'topics-all', params: {cat: 4}}">
				<div class="box">
					<div class="data">
						<div class="title nobr">Poder Judiciário</div>
					</div>
				</div>
			</router-link>
			<router-link :to="{ name: 'topics-all', params:{cat: 6}}">
				<div class="box">
					<div class="data">
						<div class="title nobr">Governo Estadual</div>
					</div>
				</div>
			</router-link>
			<router-link :to="{ name: 'topics-all', params:{cat: 7}}">
				<div class="box">
					<div class="data">
						<div class="title nobr">Poder Legislativo</div>
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
import axios from 'axios';


export default {
	name: 'ForumTwoItem',
	data() {
		return {
			data: [],
			prev: 0,
			next: 0,
			total: 0,
			search: ''
		}
	},
	methods: {
		async getTopics(p=1){
			const req = await axios.post('async/topics/get',{
				pag: p,
				search: this.search,
				cat: 4
			})
			this.data = req.data.topics
			this.prev = req.data.prev
			this.next = req.data.next
			this.total = req.data.total

			if(req.data.error){
				// this.$store.commit('Alert', req.data.text)
			}
		}
	},
	mounted(){
		this.getTopics()
	}
}
</script>

<style scoped>

.forum {margin-top: 30px;}

.item .top .icon {
	/* background-image: url(../../assets/icon2.png); */
}

/* .item .top .title {color: #e49013;}
.item .top .actions .input i {color: #e49013;}
.item .top .actions > button {background-color: #e49013;} */

.forum .content {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 20px;
}

.forum .content .box {
	width: calc(100% / 3 - 8px);
	height: 70px;
	border-radius: 8px;
	background-color: #231F31;
	border: 1px solid #000;
	padding: 0 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all .4s ease-in-out;
	margin-bottom: 10px;
}


.content .box .data .title {
	width: 100%;
	height: 20px;
	font-size: 15px;
	font-weight: 600;
	color: #fff;
}
.content .box:hover .data .title {color: #06BDF6;}

</style>