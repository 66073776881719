<template>
    <div class="events aside-item">
        <div class="top">
            <div class="bar"></div>
            <div class="title">Agenda de eventos</div>
        </div>
        <div class="content">
            <div class="box" v-for="(n, i) in data" :key="i">
                <div class="image" :style="{backgroundImage: 'url('+n.image+')'}"></div>
                <div>
                    <router-link :to="{ name: 'news', params: {id: n.id, url: n.url}}">
                        <div class="title nobr">{{ n.title }}</div>
                    </router-link>
                    <div class="date" :class="{'live' : n.event_time == 'Acontecendo agora.'}">{{ n.event_time }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';



export default {
    name: 'EventsItem',
    data() {
        return {
            data: [],
        }
    },
    methods: {
        async getEvents(){
            const req = await axios.get('async/news/events')
            this.data = req.data.news
        }
    },
    mounted(){
        this.getEvents()
    }
}
</script>

<style scoped>

.events {width: 100%;}
.aside-item .top .bar {background-color: #444;}

.events .content .box {
    width: calc(100% + 20px);
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #231F31;
    padding: 10px;
    margin-left: -10px;
}

.events .content .box .image {
    width: 100px;
    height: 48px;
    border-radius: 8px;
    background-color: #eee;
    background-position: center;
    background-size: cover;
}

.events .box .title {
    width: 100%;
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: rgba(255,255,255,.5);
    margin-left: 20px;
}

.events .box .date {
    width: 100%;
    height: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    margin: 4px 0 0 20px;
}
.events .box .date.live {color: #FF008A;}
</style>