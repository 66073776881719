<template>
    <div class="player">
        <div class="listeners"><i class="fa-solid fa-users"></i>&nbsp;{{ data.ouvintes }}</div>
        <div class="box-avatar">
            <div class="avatar" :style="{backgroundImage: 'url(https://habblive.in/ximager.php,quser='+data.locutor+'.pagespeed.ic.cl2Qmha-ms.webp)'}"></div>
        </div>
        <div class="box-data">
            <div class="info nobr"><span>{{ data.locutor }}</span> em <span>{{ data.programa }}</span></div>
            <div class="music nobr">Tocando <span>{{ data.music }}</span></div>
        </div>
        <div class="btn play" @click="play(!playing)">
                <i v-show="playing == true" class="fa-solid fa-pause"></i>
				<i v-show="playing == false" class="fa-solid fa-play"></i>
            </div>
        <div class="volume">
            <i class="fa-solid fa-volume-low"></i>
            <input type="range" name="volume" id="vol" max="100" min="0" step="1" v-model="volume" @change="rangeVol(volume)">
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'PlayerItem',
    data() {
        return {
            data: [],
            volume: 60,
            playing: true,
            audio: ''
        }
    },
    methods: {
        play(p){
            this.playing = p
            if(p == true){ 
                this.volume = 60
                this.audio.volume = 0.6
            }else{
                this.volume = 0
                this.audio.volume = 0
            }
        },
        async getInfo(){
            const req = await axios.get('async/status')

            this.data = req.data
        },
        rangeVol(){
			const ele = document.getElementById('vol');

			let value = ele.value;
			ele.style.background = 'linear-gradient(to right, var(--color2) '+value+'%, var(--color2) ' + value + '%, #1E1E1E  0%, #1E1E1E  100%)'
			ele.setAttribute('value', this.volume)

			this.audio.volume = value/100
		},
        async player(){
            const req = await axios.get("async/status");
            this.audio = new Audio(`https://${req.data.ip}/${req.data.port}/stream`);

            this.audio.addEventListener("canplaythrough", () => {
                this.audio.play().catch(() => {
                    window.addEventListener('click', () => {
                        this.audio.play()
                    }, { once: true })
                })
            });
        }
    },
    mounted () {
        this.getInfo()
        this.player();
        // setInterval(() => {
        //     this.getInfo()
        // }, 10000);
    },
}
</script>

<style scoped>

.player {
    position: relative;
    width: 45%;
    height: 68px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.40) 0%, rgba(43, 48, 70, 0.00) 100%);
    margin-top: 16px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.player .listeners {
    position: absolute;
    bottom: -10px;
    left: -10px;
    width: auto;
    height: 22px;
    background-color: var(--color2);
    border-radius: 8px;
    color: #fff;
    padding: 0 10px;
    line-height: 22px;
    font-size: 13px;
    font-weight: 500;
}

.player .box-avatar {
    width: 72px;
    height: 38px;
    border-radius: 10px;
    background-color: #000;
    margin-left: 10px;
}

.player .box-data {
    width: 50%;
}

.player .box-data .info {
    width: 100%;
    height: 20px;
    white-space: nowrap;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
}

.player .box-data .music {
    width: 100%;
    height: 20px;
    color: #fff;
    font-size: 14px;
    margin-top: 6px;
}

.player .box-data span {font-weight: 600;}

.player .btn {
    width: 32px;
    height: 32px;
    background-color: rgba(255, 255, 255, .1);
    border-radius: 6px;
    text-align: center;
    line-height: 32px;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
}

.player .volume {
    position: relative;
    width: auto;
    height: 32px;
    background-color: rgba(255, 255, 255, .1);
    border-radius: 6px;
    line-height: 32px;
    font-size: 12px;
    color: #fff;
    padding: 0 10px;
}

.player .volume input {
    position: relative;
    width: 120px;
    height: 6px;
    margin: 0px 0 0 6px;
    display: inline;
    -webkit-appearance: none;
	border-radius: 4px;
	background-color: rgba(36,78,102,.07);
	background: linear-gradient(to right, var(--color2) 60%, var(--color2) 60%, #1E1E1E 0%, #1E1E1E 100%);
}

.player .volume input::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #fff;
	cursor: pointer;
	box-shadow: 0 0 6px rgba(0,0,0,.2);
	margin-top: -1px;
}

@media (max-width: 500px) {
    .player {
        width: calc(100% - 40px);
        height: auto;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px;
        margin-left: 20px;
    }

    .player .box-data,
    .player .volume,
    .player .btn {margin-left: 10px;}

    .player .volume {margin-top: 10px;}
}
</style>