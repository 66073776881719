<template>
	<template v-if="maintenance">manu</template>
	<template v-else>
		<HeaderItem/>
		<router-view :key="this.$route.fullPath"/>
		<FooterItem/>
		<Transition name="fade">
			<div class="alert" v-show="$store.state.textAlert != null">{{ $store.state.textAlert }}</div>
		</Transition>
	</template>
</template>

<script>
import HeaderItem from './components/header.vue';
import FooterItem from './components/footer.vue'

export default {
	name: 'App',
	components: {
		HeaderItem,
		FooterItem
	},
	data() {
		return {
			maintenance: false
		}
	},
	methods: {

	},
	mounted(){

		this.$store.dispatch('init')
	}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

body {font-family: 'Mulish', sans-serif;background-color: var(--color1);}
* {padding: 0;margin: 0;box-sizing: border-box;}

:root {
	--width: 1400px;
	--color1: #000;
	--color2: #640f10;
}

button,textarea,li,ul,select,input,a {border:0;list-style: none;background-color: transparent;outline: none;color: inherit;text-decoration: none;font-family: 'Mulish', sans-serif;}
button[disabled] {opacity: .6;}
i[aria-hidden="true"] {pointer-events: none;}
a{width: inherit;display: contents;}

#app {
	position: relative;
	z-index: 0;
}

.nobr {white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
.center {
  position: relative;
  width: var(--width);
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  margin: 0 auto;
}

.item {
	width: 100%;
	height: auto;
}

.item .top {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: space-between;
}

.item .top .title {
	position: relative;
	width: 36%;
	height: 100%;
	line-height: 50px;
	font-size: 18px;
	font-weight: 800;
	color: #ccc;
	background: rgb(19,13,37);
	background: linear-gradient(270deg, rgba(19,13,37,0) 0%, rgba(19,13,37,1) 100%);
	border-radius: 40px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.item .top .title .icon {
	width: 50px;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	font-size: 20px;
	text-align: center;
	line-height: 50px;
	color: #ccc;
}
.item .top .title span {margin-left: 6px;}

.item .top .actions {
	width: 50%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	user-select: none;
}

.item .top .actions .btn {
	width: auto;
	height: 36px;
	background-color: rgba(255,255,255,.1);
	border-radius: 8px;
	text-align: center;
	line-height: 36px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	padding: 0 10px;
	margin-right: 8px;
}

.item .top .actions .btn i {color: #ccc;}

.item .top .actions .input {
	position: relative;
	width: auto;
	color: #fff;
	margin-right: 8px;
}

.item .top .actions .input input {
	width: 200px;
	height: 36px;
	border-radius: 8px;
	background-color: rgba(255,255,255,.1);
	padding: 0 0 0 34px;
}
.item .top .actions .input input::placeholder {color: #ccc;}

.item .top .actions .input i {
	position: absolute;
	top: 12px;
	left: 10px;
	font-size: 14px;
	color: #ccc;
}

.item .top .actions > button {
	position: relative;
	width: 36px;
	height: 36px;
	border-radius: 8px;
	margin-right: 8px;
	background-color: #ccc;
}

.aside-item {
	height: auto;
	background-color: #ccc;
	border-radius: 8px;
	padding: 10px;
}

.aside-page {
	width: 34%;
}

.aside-item .top {
	position: relative;
	width: 100%;
	height: 50px;
	background-color: #231F31;
	border-radius: 8px;
	overflow: hidden;
}

.aside-item .top .bar {
	position: absolute;
	top: -80px;
	left: -10px;
	width: 50%;
	height: 200px;
	transform: rotate(20deg);
}

.aside-item .top .title {
	position: relative;
	width: 100%;
	height: 100%;
	line-height: 50px;
	font-size: 20px;
	text-align: center;
	font-weight: 800;
	color: #fff;
}

.aside-item .content {
	position: relative;
	width: 100%;
	padding-top: 10px;
}

.page {
	position: relative;
	width: var(--width);
	height: auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin: 30px auto;
}

.page .read {
	width: 64%;
}

.page .read > .top {
	width: 100%;
	height: 50px;
	background: rgb(19,13,37);
	background: linear-gradient(270deg, rgba(19,13,37,0) 0%, rgba(19,13,37,1) 100%);
	border-radius: 40px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	user-select: none;
}

.page .read > .top .icon {
	float: left;
	width: 50px;
	height: 50px;
	background-repeat: no-repeat;
	background-position: center;
	font-size: 20px;
	text-align: center;
	line-height: 50px;
	color: #ccc;
}

.page .read > .top .title span {
	width: calc(100% - 60px);
	margin: 14px 0 0 10px;
	font-size: 18px;
	font-weight: 800;
	color: #ccc;
	white-space: nowrap;
}

.page .read > .content {
	width: 100%;
	margin-top: 20px;
	border-radius: 8px;
	padding: 10px;
	background-color: #342F46;
	color: #fff;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
}

.modal .blur {
	position: absolute;
	width: 100%;
	height: 100%;
	filter: blur(10px);
	background-color: rgba(255,255,255,.11);
	z-index: 1;
}

.modal .box {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 600px;
	height: auto;
	background-color: #111;
	border-radius: 8px;
	border-top: 2px solid var(--color1);
	padding: 20px;
	transform: translate(-50%, -50%);
	z-index: 99;
	user-select: none;
	box-shadow: 0 4px 6px rgba(0,0,0,.15);
}

.modal .box .top {
	display: flex;
	justify-content: space-between;
	height: 40px;
	width: 100%;
}

.modal .box .top .title {
	font-size: 20px;
	font-weight: 800;
	color: #fff;
}

.modal .box .top .close {
	width: 30px;
	height: 30px;
	border-radius: 8px;
	color: #fff;
	background-color: #FF0000;
	text-align: center;
	line-height: 30px;
	font-size: 14px;
}

.modal .box .content,
.modal .box .content form {
	width: 100%;
	height: auto;
}

.modal .box .content span {
	float: left;
	width: 100%;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	color: #fff;
	margin-top: 10px;
}

.modal .box .content input {
	width: 100%;
	height: 40px;
	border: 1px solid #8D8A97;
	border-radius: 8px;
	margin-top: 4px;
	padding: 10px 15px;
	color: #fff;
	box-shadow: 0 2px 2px rgba(0,0,0,.2);
	background-color: #342F46;
}

.modal .box .content .forgot {
	float: left;
	width: 100%;
	height: 20px;
	color: #06BDF6;
	font-size: 14px;
	font-weight: 600;
	margin-top: 10px;
}

.modal .box .content button {
	float: left;
	width: auto;
	height: 36px;
	border-radius: 8px;
	background-color: var(--color1);
	color: #fff;
	font-size: 14px;
	font-weight: 800;
	display: flex;
	align-items: center;
	padding: 0 10px;
	margin-top: 14px;
	transition: all .3s ease;
}
.modal .box .content button:hover {opacity: .9;}
.modal .box .content button:active {background-color: #08B04B;}

.modal .box .content .replace {
	float: left;
	width: auto;
	height: 20px;
	margin: 22px 0 0 20px;
	color: #06FF6A;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.alert {
	position: fixed;
	right: 20px;
	bottom: 20px;
	width: auto;
	height: 60px;
	background-color: rgba(0,0,0,.65);
	border-radius: 8px;
	line-height: 60px;
	color: #fff;
	text-align: center;
	font-size: 18px;
	font-weight: 600;
	padding: 0 30px;
	user-select: none;
	z-index: 999999999;
}

@media (max-width: 500px) {
	.center {width: 100%;}

	.item .top .title {width: 42%;white-space: nowrap;}

	.item .top .actions {width: 56%;}
	.item .top .actions .btn {width: 30px;}
	.item .top .actions .btn.plus {display: none;}
	.item .top .actions .input input {width: 120px;}
	.item .content {padding: 0 10px;}
	.item .content .box {width: calc(100% - 10px) !important;}

	.page {width: 100%;padding: 0 10px;}
	.page .read > .content {padding: 0;}

	.modal {
		width: 100%;
	}

	.modal .box {
		left: 6px;
		width: calc(100% - 12px);
		transform: translateY(-50%);
	}
}
</style>