<template>
	<div class="forum item">
		<div class="top">
			<div class="title">
				<div class="icon"><i class="fa-solid fa-comments"></i></div>
				<span>Discussões</span>
			</div>
			<div class="actions">
				<div class="input">
					<i class="fa-solid fa-magnifying-glass"></i>
					<input type="text" placeholder="Pesquise" v-model="search" @keypress.enter="getTopics()">
				</div>
				<button :disabled="prev <= 1"><i class="fa-solid fa-arrow-left"></i></button>
				<button :disabled="next > total"><i class="fa-solid fa-arrow-right"></i></button>
			</div>
		</div>
		<div class="content">
			<div class="box" v-for="(t, i) in data" :key="i">
				<div class="image" :style="{backgroundImage: 'url('+t.image+')'}"></div>
				<div class="data">
					<router-link :to="{ name: 'topic', params: { id: t.id, url: t.url }}">
						<div class="title nobr">{{ t.title }}</div>
					</router-link>
					<div class="autor">
						Por <span>{{t.user}}</span> • na categoria <span>{{ t.category }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';


export default {
	name: 'ForumItem',
	data() {
		return {
			data: [],
			prev: 0,
			next: 0,
			total: 0,
			search: ''
		}
	},
	methods: {
		async getTopics(p=1){
			const req = await axios.post('async/topics/get',{
				pag: p,
				search: this.search
			})
			this.data = req.data.topics
			this.prev = req.data.prev
			this.next = req.data.next
			this.total = req.data.total

			if(req.data.error){
				this.$store.commit('Alert', req.data.text)
			}
		}
	},
	mounted(){
		this.getTopics()
	}
}
</script>

<style scoped>

.forum {margin-top: 30px;}

.item .top .icon {
	/*background-image: url(../../assets/icon2.png);*/
}

/* .item .top .title {color: #fff;}
.item .top .actions .input i {color: #fff;}
.item .top .actions > button {background-color: #fff;} */

.forum .content {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 20px;
}

.forum .content .box {
	width: calc(100% / 2 - 8px);
	height: 104px;
	border-radius: 8px;
	background-color: #231F31;
	border: 1px solid #000;
	padding: 0 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: all .4s ease-in-out;
	margin-bottom: 10px;
}

.content .box .image {
	width: 120px;
	height: 80px;
	border-radius: 8px;
	background-position: center;
	background-size: cover;
}

.content .box .data {
	width: calc(100% - 130px);
	height: auto;
}

.content .box .data .title {
	width: 100%;
	height: 20px;
	font-size: 15px;
	font-weight: 600;
	color: #fff;
}
.content .box:hover .data .title {color: #06BDF6;}

.content .box .data .autor {
	color: rgba(255,255,255,.4);
	font-size: 14px;
	font-weight: 600;
	margin-top: 4px;
}
</style>