import { createStore } from 'vuex'
import axios from 'axios';

export default createStore({
  state: {
    userData: {},
    logged: false,
    textAlert: null,
    color1: '',
    color2: '',
    theme: {}
  },
  getters: {
  },
  mutations: {
    tema(state, data){
      let root = document.documentElement;
      root.style.setProperty("--color1", data.color1)
      root.style.setProperty("--color2", data.color2)
      state.theme = data
    },
    Alert(state, text){
      state.textAlert = text;
      setTimeout(() => {
        state.textAlert = null;
      }, 3500);
      // this.$store.commit('Alert', req.data.text)
    },
    userData(state, data){
      state.userData = data
    }
  },
  actions: {
    async init(store){
      const req = await await axios.get('async/init')
      const tema = await await axios.get('async/theme/get')
      if(tema.data != false){
        store.commit('tema', tema.data[0])
      }

      if(req.data.user != null){
        store.state.logged = req.data.logged
        store.commit('userData', {
          user: req.data.user.user,
          photo: req.data.user.photo
        })
      }
      return req.data.logged
    }
  },
  modules: {
  }
})
