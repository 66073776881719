<template>
    <footer>
        <div class="img"></div>
        <div class="links">
            <ul>
                <router-link :to="{ name: 'home'}">
                    <li><p>Início</p></li>
                </router-link>
                <router-link :to="{ name: 'topics-all'}">
                    <li><p>Topícos</p></li>
                </router-link>
                <router-link :to="{ name: 'news-all'}">
                    <li><p>Noticias</p></li>
                </router-link>
            </ul>
        </div>
        <div class="footer">
            <div class="center">
                <div class="logo"></div>
                <div class="line"></div>
                <div class="about">
                    O PROJECT RP é um servidor de roleplay autônomo, independente e legalizado, criado para a comunidade de GTA San Andreas. Nosso objetivo é proporcionar uma experiência imersiva e divertida para todos os jogadores, respeitando as regras e diretrizes do roleplay.
 você viverá uma nova realidade dentro do universo de San Andreas, com interações emocionantes, eventos dinâmicos e uma comunidade ativa e acolhedora.<br>
 Fundado e operado por Guilherme Pasquale, Inayra Pasquale, Koringa Monster e Domes Enguissa.
                </div>
            </div>
        </div>
        <div class="dev">Desenvolvido por Kaio Felps e <a href="https://pauloferraco.com.br" target="_blank" rel="noopener noreferrer">Paulo Ferraço</a>.</div>
    </footer>
</template>

<script>

export default {
    name: 'FooterItem'
}
</script>

<style scoped>

footer {
    position: relative;
    float: left;
    width: 100%;
    height: 300px;
    background: rgb(19,13,37);
    background: linear-gradient(180deg, rgba(19,13,37,0) 0%, rgba(19,13,37,1) 80%);
    margin-top: 30px;
}

footer .links {
    float: left;
    width: 100%;
    height: 40px;
    margin-top: 20px;
}

.links ul {
    list-style: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    user-select: none;
}

.links ul li {
    width: auto;
    height: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 16px;
    color: #06BDF6;
    margin: 0 6px;
    cursor: pointer;
}

.links ul li p {
    font-weight: 600;
    text-decoration: underline;
}

footer .footer {
    float: left;
    width: 100%;
    height: 80px;
    margin: 40px 0 20px 0;
}

footer .footer .center {justify-content: space-evenly;align-items: center;}

footer .footer .logo {
    width: 100px;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

footer .footer .line {
    width: 1px;
    height: 80px;
    background-color: rgba(255,255,255,.2);
}

footer .footer .about {
    width: 66%;
    height: auto;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

footer .dev {
    float: left;
    position: relative;
    margin: 60px 0 0 0;
    width: 100%;
    height: 36px;
    text-align: center;
    line-height: 36px;
    user-select: none;
    font-size: 13px;
    color: rgba(255,255,255,.5);
    border-top: 2px solid #000;
    background-color: #130D25;
    z-index: 1;
}

footer .img {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 1000px;
    height: 180px;
    background-image: url(../assets/asteroides.png);
    background-repeat: no-repeat;
    z-index: 0;
}

@media (max-width: 500px) {
    footer {height: auto;overflow: hidden;}
    .footer {height: auto !important;padding: 20px 10px;}
    .footer .center {flex-wrap: wrap;justify-content: center;}
    .footer .logo {width: 100% !important;}
    .footer .about {width: 100% !important;}
    .footer .line {display: none;}
    footer .dev {margin: 0}
}
</style>