<template>
	<div class="badges aside-item">
		<div class="top">
			<div class="bar"></div>
			<div class="title">Conquistas</div>
		</div>
		<div class="content">
			<div class="box" v-for="(b, i) in data" :key="i" :style="{backgroundImage: 'url('+b.image+')'}">
				<ToolTip :title="b.title" top="-84%" line="" />
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import ToolTip from '../util/tooltip.vue';

export default {
	name: 'EventsItem',
	components: {
		ToolTip,
	},
	data() {
		return {
			data: []
		}
	},
	methods: {
		async getBadges(){
			const req= await axios.get('async/badges_free/get')
			this.data = req.data
		}
	},
	mounted(){
		this.getBadges()
	}
}
</script>

<style scoped>

.badges {width: 100%; margin-top: 30px;}
.aside-item .top .bar {background-color: #444;}

.badges .content {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.badges .content .box {
	position: relative;
	width: 58px;
	height: 58px;
	border-radius: 8px;
	background-color: rgba(255,255,255,.05);
	background-position: center;
	background-repeat: no-repeat;
	transition: all .3s ease;
	margin: 0 4px 0 1px;
}
.badges .content .box:hover {
	background-color: rgba(255,255,255,.15);
}


</style>