<template>
	<div class="slide" :class="{'none': slides.length == 0}">
		<div v-for="(s, index) in slides" :key="index" v-show="currentIndex == index" class="box" :style="{backgroundImage: 'url('+s.image+')'}">
			<div class="title" @click="next()">Acesse aqui</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	name: 'SlideItem',
	data() {
		return {
			slides: [],
			timer: null,
			currentIndex: 0
		}
	},
	methods: {
		async getSlides(){
			const req = await axios.get('async/slide/get')
			this.slides = req.data
		},
		startSlide(){
			this.getSlides();
			if(this.slides.length > 0){
				this.next();
				setInterval(() => {
					this.next();
				}, 10000);
			}
		},
		next() {
			if(this.currentIndex == (this.slides.length - 1)){
				this.currentIndex = 0
			}else{
				this.currentIndex += 1;
			}
			
		},
		prev() {
			this.currentIndex -= 1;
		}
	},
	mounted() {
		this.startSlide();
	}
}
</script>

<style scoped>

.slide {
	position: relative;
	width: 520px;
	height: 270px;
	background-color: #ccc;
	border-radius: 8px;
}
.slide.none {display: none;}

.slide .box {
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	border-radius: 8px;
}

.slide .title {
	position: absolute;
	left: 50%;
	bottom: 10px;
	width: auto;
	height: 36px;
	background-color: rgba(0,0,0,.5);
	border-radius: 20px;
	transform: translateX(-50%);
	font-size: 18px;
	font-weight: 600;
	color: #fff;
	padding: 0 20px;
	line-height: 36px;
	user-select: none;
}
</style>