<template>
	<header>
		<div class="nav">
			<div class="center">
				<PlayerItem/>
				<NavBar/>
			</div>
		</div>
		<div class="login-area">
			<div class="center">
				<div class="register" v-if="!$store.state.logged">
					<span>Ainda não tem uma conta?</span>
					<button @click="modR = true" class="btn-reg"><ph-user-plus :size="19" color="var(--color1)" />&nbsp;&nbsp;REGISTRE-SE</button>
					<button @click="modL = true" class="btn-login"><ph-sign-in :size="19" />&nbsp;&nbsp;LOQUE-SE</button>
				</div>
				<div v-else-if="$store.state.logged" class="logged">
					<router-link :to="{ name: 'profile', params: {user: ''}}">
						<div class="user">{{ $store.state.userData.user }}</div>
					</router-link>
					<router-link :to="{ name: 'settings'}">
						<button class="config"><i class="fa-solid fa-gear"></i>&nbsp;&nbsp;CONFIGURAÇÕES</button>
					</router-link>
					<router-link :to="{ name: 'post-topic'}">
						<button class="post"><i class="fa-solid fa-pencil"></i>&nbsp;&nbsp;CRIAR TÓPICO</button>
					</router-link>
					<button class="desl" @click="logout"><ph-sign-out :size="16" color="#FF0000" />&nbsp;&nbsp;DESLOGAR</button>
				</div>
				<div v-else></div>
				<div class="search">
					<i class="fa-solid fa-magnifying-glass"></i>
					<input type="text" placeholder="Buscar no site..." v-model="search" @keyup.enter="getSearch()">
				</div>
			</div>
		</div>
		<div class="header" :style="{backgroundImage: 'url('+$store.state.theme.header+')'}">
			<div class="center">
				<div class="logo" :style="{backgroundImage: 'url()'}"></div>
				<SlideItem/>
			</div>
		</div>
	</header>
	<Transition name="fade">
		<ModalLogin v-if="modL" @close="modL = false" @register="modL = false, modR = true"/>
	</Transition>
	<Transition name="fade">
		<ModalRegister v-if="modR" @close="modR = false" @login="modL = true, modR = false"/>
	</Transition>
</template>

<script>
import PlayerItem from './header/player.vue'
import NavBar from './header/nav.vue'
import SlideItem from './header/slide.vue'

import ModalLogin from './modals/login'
import ModalRegister from './modals/register'
import axios from 'axios'

export default {
	name: 'HeaderItem',
	data() {
		return {
			modL: false,
			modR: false,
			modC: false,
			modT: false,
			search: ''
		}
	},
	components: {
		PlayerItem,
		NavBar,
		SlideItem,
		ModalLogin,
		ModalRegister
	},
	methods:{
		async logout(){
			const req = await axios.get('async/logout')
			this.$store.commit('Alert', req.data.text)
			if(!req.data.error){
				this.$store.state.logged = false
			}
		},
		async getSearch(){
			this.$router.push({ path: 'search', query: {s: this.search} })
		}
	}
}
</script>

<style scoped>

header {
	position: relative;
	z-index: 0;
}

header .nav {
	width: 100%;
	height: 100px;
	background-color: #444;
	display: flex;
	justify-content: space-between;
}

.login-area {
	width: 100%;
	height: 60px;
	background-color: #000;
	display: flex;
	align-items: center;
}


.login-area .register {
	display: flex;
	align-items: center;
}

.login-area .register span {
	width: auto;
	height: 20px;
	color: #fff;
	font-size: 15px;
	font-weight: 500;
}

.login-area .register button {
	width: auto;
	height: 36px;
	border-radius: 8px;
	padding: 0 16px;
	color: var(--color1);
	background-color: rgba(255,255,255,.2);
	margin-left: 10px;
	font-size: 14px;
	font-weight: 800;
	display: flex;
	align-items: center;
}


.login-area .register .btn-login:hover {opacity: .9;}
.login-area .register .btn-login:active {background-color: var(--color2);}

.login-area .register .btn-reg:hover {background-color: rgba(255,255,255,.15);}
.login-area .register .btn-reg:active {background-color: rgba(255,255,255,.2);}

.login-area .register .btn-login:last-of-type {background-color: var(--color1);color: #fff;}

.logged {
	width: auto;
	height: 46px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background-color: rgba(255,255,255,.08);
	border-radius: 8px;
	padding: 0 14px 0 10px;
	margin-top: 7px;
}

.logged .avatar {
	width: 40px;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center -8px;
}

.logged .user {
	width: auto;
	height: 20px;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	margin: 0 20px 0 10px;
}

.login-area .logged button {
	width: auto;
	height: 36px;
	border-radius: 8px;
	font-size: 14px;
	font-weight: 800;
	margin-left: 10px;
	padding: 0 10px;
	cursor: pointer;
}

.login-area .logged .config {
	background-color: #2B3046;
	color: #fff;
	border-top: 1px solid rgba(255,255,255,.5);
}

.login-area .logged .post {
	background-color: var(--color2);
	color: #000;
}

.login-area .logged .desl {
	background-color: rgba(255,255,255,.1);
	color: #fff;
	display: flex;
	align-items: center;
}

.search {
	position: relative;
	width: 20%;
	height: 60px;
	border-left: 1px solid rgba(255,255,255,.15)
}

.search i {
	position: absolute;
	top: 22px;
	left: 34px;
	color: var(--color2);
	font-size: 15px;
}

.search input {
	position: relative;
	height: 40px;
	width: calc(100% - 40px);
	top: 10px;
	left: 20px;
	border-radius: 8px;
	color: #fff;
	font-size: 14px;
	border: 1px solid rgba(255,255,255,.2);
	padding: 0 10px 0 40px;
}

.header {
	width: 100%;
	height: 380px;
	background-color: transparent;
	background-position: center;
	background-size: contain;
}

.header .center {align-items: center;height: 100%;}

.logo {
	width: 300px;
	height: 200px;
	background-repeat: no-repeat;
	background-position: center;
	margin-left: 100px;
}

@media (max-width: 1400px) {
	.header {
		background-position: center;
		background-size: cover;
	}
}

@media (max-width: 500px) {
    header .nav {
        width: 100%;
		height: auto;
    }

	.center {display: block;}

	.login-area {
		width: 100%;
		height: auto;
		padding: 10px;
	}

	.login-area .center {flex-wrap: wrap;justify-content: center;display: flex;}

	.search {width: 80%;margin-top: 10px;border:0;}

	.header {background-position: 100%;background-size: cover;}

	.logo {
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin-left: 0;
	}

}
</style>