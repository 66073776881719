<template>
	<div class="news item">
		<div class="top">
			<div class="title">
				<div class="icon"><i class="fa-solid fa-newspaper"></i></div>
				<span>Feed de Notícias</span>
			</div>
			<div class="actions">
				<router-link :to="{ path: '/news-all'}">
					<div class="btn plus"><i class="fa-solid fa-plus"></i>&nbsp;&nbsp;VEJA MAIS</div>
				</router-link>
				<div class="input">
					<i class="fa-solid fa-magnifying-glass"></i>
					<input type="text" placeholder="Pesquise" v-model="search" @keypress.enter="getNews()">
				</div>
				<button :disabled="prev <= 1"><i class="fa-solid fa-arrow-left"></i></button>
				<button :disabled="next > total"><i class="fa-solid fa-arrow-right"></i></button>
			</div>
		</div>
		<div class="content">
			<div class="box" v-for="(n, i) in news" :key="i">
				<div class="image" :style="{backgroundImage: 'url('+n.image+')'}">
					<div class="hover">
						<div class="autor">
							<span>{{ n.user }}</span>
						</div>
						<div class="category">{{n.category}}</div>
					</div>
				</div>
				<router-link :to="{ name: 'news', params: { id: n.id, url: n.url }}">
					<div class="title">{{ n.title }}</div>
				</router-link>
				<div class="date">{{n.date}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';



export default {
	name: 'NewsItem',
	data() {
		return {
			news: [],
			total: 0,
			prev: 0,
			next: 0,
			search: ''
		}
	},
	methods:{
		async getNews(p=1){
			const req = await axios.post('async/news/get', {
				pag: p,
				search: this.search
			})
			this.news = req.data.news
			this.prev = req.data.prev
			this.next = req.data.next
			this.total = req.data.total
			
			if(req.data.error){
				this.$store.commit('Alert', req.data.text)
			}
		}
	},
	mounted(){
		this.getNews()
	}
}
</script>

<style scoped>

/* .news {width: 65%;} */

.item .top .icon {
	/* background-image: url(../../assets/icon1.png); */
}
/* .item .top .actions .input i {color: #06BDF6;}
.item .top .actions > button {background-color: #06BDF6;} */

.news .content {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	margin-top: 10px;
}

.news .content .box {
	width: calc(100% / 3 - 8px);
	height: 196px;
	background-color: #342F46;
	border-radius: 8px;
	margin-top: 10px;
}

.news .content .box .image {
	width: calc(100% - 20px);
	height: 98px;
	background-position: center;
	background-size: cover;
	border-radius: 8px;
	margin: 10px;
	display: flex;
	justify-content: space-between;
}
.news .content .box .image .hover {
	width: 100%;
	height: 80%;
	background: rgb(19,13,37);
	background: linear-gradient(0deg, rgba(19,13,37,0) 0%, rgba(19,13,37,1) 100%);
	border-radius: 8px 8px 0 0;
	display: flex;
	justify-content: space-between;
	padding: 10px;
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease-in-out;
}
.news .content .box:hover .image .hover {opacity: 1;visibility: visible;}

.box .image .autor {
	width: 50%;
	height: 30px;
	font-size: 14px;
	font-weight: 600;
	color: #fff;
	display: flex;
	line-height: 30px;
}

.box .image .autor .avatar {
	width: 30px;
	height: 30px;
	background-position: center;
	background-repeat: no-repeat;
}

.box .image .category {
	float: right;
	width: auto;
	height: 30px;
	background-color: rgba(0,0,0,.6);
	text-align: center;
	line-height: 30px;
	border-radius: 20px;
	padding: 0 8px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
}

.content .box .title {
	width: 100%;
	height: 42px;
	padding: 0 10px;
	font-size: 16px;
	font-weight: 600;
	color: #fff;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: initial;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	cursor: pointer;
}

.content .box .date {
	width: 100%;
	height: 20px;
	color: rgba(255,255,255,.5);
	font-size: 14px;
	font-weight: 600;
	padding: 0 10px;
	margin-top: 8px;
}
</style>