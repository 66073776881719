<template>
	<div class="modal">
		<div class="blur" @click="$emit('close')"></div>
		<div class="box">
			<div class="top">
				<div class="title">Registre-se</div>
				<div class="close" @click="$emit('close')"><i class="fa-solid fa-xmark"></i></div>
			</div>
			<div class="content">
				<form @submit.prevent="postRegister">
					<span>Nickname</span>
					<input type="text" placeholder="Nick" v-model="user">
					<span>Senha</span>
					<input type="password" placeholder="••••••" min="6" max="30" v-model="pass">
					<span>Repita a senha</span>
					<input type="password" placeholder="••••••" min="6" max="30" v-model="passr">
					<div class="forgot">Esqueci minha senha</div>
					<button><ph-user-plus :size="19" />&nbsp;CRIAR CONTA</button>
					<span class="replace" @click="$emit('login')">Tenho uma conta!</span>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	name: 'ModalRegister',
	data() {
		return {
			user: '',
			pass: '',
			passr: ''
		}
	},
	methods: {
		async postRegister(){
			const req = await axios.post('async/register', {
				user: this.user,
				pass: this.pass,
				passr: this.passr
			})
			
			this.$store.commit('Alert', req.data.text)
			
			if(req.data.success){
				this.$emit('close')
				this.$emit('login')
			}
		}
	}
}
</script>

<style scoped>

</style>