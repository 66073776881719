<template>
	<div class="modal">
		<div class="blur" @click="$emit('close')"></div>
		<div class="box">
			<div class="top">
				<div class="title">Login</div>
				<div class="close" @click="$emit('close')"><i class="fa-solid fa-xmark"></i></div>
			</div>
			<div class="content">
				<form @submit.prevent="getLogin">
					<span>Nickname</span>
					<input type="text" placeholder="Nick" v-model="user">
					<span>Senha</span>
					<input type="password" placeholder="••••••" v-model="pass">
					<div class="forgot">Esqueci minha senha</div>
					<button><ph-sign-in :size="19" />&nbsp;ENTRAR</button>
					<span class="replace" @click="$emit('register')">Não tenho uma conta</span>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'ModalLogin',
	data() {
		return {
			user: '',
			pass: ''
		}
	},
	methods:{
		async getLogin(){
			const req = await axios.post('async/login', {
				user: this.user,
				pass: this.pass
			})
			if(req.data.logged){
				this.$store.state.logged = true

				this.$store.commit('userData', {
					id: req.data.user.id,
					user: req.data.user.user
				})
				this.$emit('close')
			}
			this.$store.commit('Alert', req.data.text)
		}
	}
}
</script>

<style scoped>

</style>