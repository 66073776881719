import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PhosphorIcons from "@phosphor-icons/vue"

const app = createApp(App)
app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)
app.use(PhosphorIcons)

axios.defaults.baseURL =  "https://www.projectroleplay.com.br/app/";

axios.defaults.headers= {
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Headers': '*',
	'Content-Type': 'application/json; charset=utf-8;',
	'Access-Control-Allow-Methods': 'GET,POST'
}

app.use(store).use(router).mount('#app')

