<template>
   <nav>
        <ul>
            <li>
                <router-link :to="{ path: '/'}">
                    <p style="border-color: #E3C319;">Início</p>
                </router-link>
            </li>
            <li v-for="(c, i) in cats" :key="i">
                <p style="border-color: #06FF6A;">{{c.category}}</p>
                <ul class="subnav">
                    <li v-for="(cc, i) in c.paginas" :key="i">
                        <router-link :to="{name: cc.url, params: {id: cc.id}}" >
                            {{ cc.title }}
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</template>

<script>
import axios from 'axios'

export default {
    name: 'NavBar',
    data() {
        return {
            cats: [],
        }
    },
    methods: {
        async getNav(){
            const req = await axios.get('async/navbar/get')
            this.cats = req.data
        }
    },
    mounted(){
        this.getNav()
    }
}
</script>

<style scoped>

nav {
    width: 55%;
    height: 100%;
    user-select: none;
}

nav > ul {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

nav ul li p {
    width: auto;
    height: 100%;
    padding: 0 10px;
    margin-right: 1px;
    border-bottom: 3px solid;
    line-height: 100px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

nav ul li .subnav {
    position: absolute;
    left: 50%;
    top: 100px;
    width: 160px;
    height: auto;
    background-color: #2B3046;
    border-radius: 0 0 8px 8px;
    padding: 4px 0;
    z-index: 99;
    transform: translateX(-32%);
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease;
}
nav ul li:hover .subnav {opacity: 1;visibility: visible;}

nav ul li .subnav li {
    width: 100%;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    border-top: 1px solid rgba(0,0,0,.2);
    color: #fff;
}
nav ul li .subnav li:first-of-type {border:0;}

@media (max-width: 500px) {
    nav {
        width: 100%;
        height: 80px;
    }
}
</style>