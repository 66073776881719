import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/noticia/:id/:url?',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/news.vue')
  },
  {
    path: '/topic/:id/:url?',
    name: 'topic',
    component: () => import(/* webpackChunkName: "topic" */ '../views/topic.vue')
  },
  {
    path: '/post-topic',
    name: 'post-topic',
    component: () => import(/* webpackChunkName: "post-topic" */ '../views/post-topic.vue')
  },
  {
    path: '/pagina/team',
    name: 'team',
    component: () => import(/* webpackChunkName: "team" */ '../views/team.vue')
  },
  {
    path: '/pagina/:id?',
    name: 'pagina',
    component: () => import(/* webpackChunkName: "page" */ '../views/page.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/settings.vue')
  },
  {
    path: '/perfil/:user?',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/search')
  },
  {
    path: '/horarios',
    name: 'hours',
    component: () => import(/* webpackChunkName: "hours" */ '../views/hours')
  },
  {
    path: '/news-all',
    name: 'news-all',
    component: () => import(/* webpackChunkName: "news" */ '../views/news-all')
  },
  {
    path: '/page-topic/:cat?',
    name: 'topics-all',
    component: () => import(/* webpackChunkName: "topics-all" */ '../views/topics-all')
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
